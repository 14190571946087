.App {
  text-align: center;
  
}
.container{
  background: url("") no-repeat center center fixed;
  background-color: rgba(0, 0, 0, 0.7);
  background-size: cover;
  width: 100vmax;
  overflow: hidden;
}

.title-logo{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 60vh;
  height: 30vh;
}

.title-logo img{
  max-width: 100%;
  max-height: 100%;
  z-index: 3;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media screen and (max-width:640px) {
  .title-logo{
    position: absolute;
    padding-top: 5vh;
    top: 0;
    left: 0;
    z-index: 3;
    width: 30vh;
    height: 30vh;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar{
  width: 5px;
}

::-webkit-scrollbar-track{
  color: rgb(255, 0, 0);
  background-color: #000000;
}

::-webkit-scrollbar-thumb{
  background-image: linear-gradient(rgb(0,0,0),#940000,rgb(0, 0, 0));
  border-radius: 25px;
}