.prizeWinnings{
    border: solid #505050;
    border-radius: 5px;
    background-color: white;
    width: 15vh;
    height: 60px;
    display: inline-block;
    padding: 1rem 1rem;
    vertical-align: middle;
    margin-left: 2vw;
}

.vaultIcon{
    padding-left: 30%;
}

.claim-prize-section{
    display: grid;
    justify-items: center;
    align-content: center;
    justify-content: center;
}

.claim-prize-text{
    width: 50%;
    color: white;
    font-family: 'RobotItalic';
    text-align: center;
    margin-top: 100px;
}

.claim-prize-buttons{
    margin-top: 50px;
}

.checkVaultText{
    color: black;
    font-family: 'RobotBold';
}

.page-sections-prize{
    height: 100%;
    width: auto;
}

.payout-mobile{
    display: none;
}

.payout-desktop{
    display: block;
}

.prize-container{
    /* padding: 1em; */
    padding-top: 1em;
    justify-content: center;
    text-align: center;
    /* display: flex; */
    border: solid #505050;
    border-radius: 20px;
    background-color: #1a1a1a;
    margin-bottom: 50px;
}

.prize-container h1{
      font-family: 'RobotBold';
    /* font-family:  Verdana, sans-serif; */
    
    color: white;
    text-align: center;
    margin-bottom: 20px;
    background-color: #1a1a1a;
}

.left-prize{
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    padding-right: 15%;
    padding-left: 15%;
}

.left-prize h1{
      font-family: 'RobotBold';
    color: white;
    font-size: 30px;
    
}

.right-prize{
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    padding-right: 13%;
    padding-left: 13%;
}



.prizes-search{
    margin-bottom: 1rem;
    padding: 2rem 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
}

.img-container-payouts{
    position: relative;
    justify-content: center;
    display: flex;
    /* margin-bottom: 50px; */
    /* width: 30vw; */
    /* background-color: #1a1a1a; */
    border-radius: 20px;
    padding: 1em;
}

.img-container-payouts img{
    position:relative;
    width: 100%;
    height: auto;
    /* border: solid #505050;  */
    /* border-radius: 20px; */
}

.winningsButton{
    height: 53px;
    font-size: large;
    background-color: #af0000;
}

@media screen and (max-width:400px) {
    .claim-prize-text p{
    font-family: 'RobotRegular';
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

}

@media screen and (max-width:655px) {
    .claim-prize-text h1{
      font-family: 'RobotBold';
        font-size: 20px !important;
        margin-bottom: 20px;
    }

    .claim-prize-text p{
    font-family: 'RobotRegular';
        font-size: 15px !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .left-prize h1{
      font-family: 'RobotBold';
        font-size: 30px;
    }

    .image-header-mobile{
        display: block !important;
    }

    .image-header-pc{
        display: none;
    }

    .image-header2{
        padding-top: 15PX;
    }
}

@media screen and (max-width: 900px) {
    .img-container-payouts img{
        /* width: 250px; */
    }

    .winningsButton{
        height: 60px;
    }

    .prizeWinnings{
        height: auto;
        padding: 0;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 10px;
    }

    .image-header2{
        width:100%;
        height:auto !important;
        /* margin-top: 50px; */
        /* padding-top: 15PX; */
    }

    .claim-prize-text{
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .payout-mobile{
        display: block;
        border-radius: 20px;
    }

    .payout-desktop{
        display: none;
    }

    .left-prize{
        padding-right: 2%;
        padding-left: 2%;
    }

    .right-prize{
        padding-right: 2%;
        padding-left: 2%;
    }

    .claim-prize-text{
        width: 90%;
    }

    .vaultIcon{
        margin-top: 30px;
        padding-left: 0;
    }
}

.image-header2{
    width:100%;
    /* height:250px; */
}

.image-header2 img{
    width:100%;
    height:auto;
}

.image-header-mobile{
    display: none;
}
