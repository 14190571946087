.table-container{
   /* padding: 3em; 
   padding-right: 5vw; */
   border: #505050 solid;
   border-radius: 15px;
   margin-bottom: 3em;
   margin-right: 3em;
   margin-left: 3em;
}

.table-container h1{
      font-family: 'RobotBold';
    /* font-family: 'poker'; */
    
    color: white; 
    text-align: center;
    margin-bottom: 20px;
    background-color: #1a1a1a;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    font-size: 30px;
    padding: 10px;

}

.timeTable{
    padding-left: 1em;
    padding-right: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.leaderboards-container{
    padding-right:10em;
    padding-left: 10em;
    padding-bottom: 10em;
}

.assign-memebrship-text{
    /* padding:3em; */
    border: #505050 solid;
    border-radius: 15px;
    margin-bottom: 5vh;
}

.assign-memebrship-text em{
    
    color: rgb(201, 3, 3);
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
}

.assign-memebrship-text h1{
      font-family: 'RobotBold';
    /* font-family: 'poker'; */
    
    color: white; 
    text-align: center;
    margin-bottom: 50px;
    background-color: #1a1a1a;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    font-size: 30px;
    padding: 10px;
}

.assign-memebrship-text p{
    font-family: 'RobotRegular';
    text-align: center;
    /* font-family: 'poker'; */
    font-size: 20px;
    font-weight: 400;
    /* padding-left: 10vh; */
    color: white;
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 1em;
    
}

.mebershipSearch{
    /* border: 1px solid black; */
    margin: 1rem;
    padding: 2rem 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
}

.uploadMembership{
    margin: 1rem;
    padding: 2rem 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
}

.membershipNumber{
    border: solid #000000; 
    border-radius: 20px;
    background-color: rgb(95, 95, 95);
    /* width: 15vh; */
    height: auto;
    display: inline-block;
    padding: 1rem 1rem;
    vertical-align: middle;
    margin-left: 2vw;
    cursor: unset;
    color: white;
}

.usernameInput{
    border: solid #505050; 
    border-radius: 20px;
    width: 15vw;
    height: auto;
    display: inline-block;
    padding: 1rem 1rem;
    vertical-align: middle;
    margin-right: 1vw;
    margin-bottom: 20px;
}

.membershipButton{
    font-size: large;
    background-color: #af0000; 
}

.timetable-section{
    width: 100%;
    display: flex;
    justify-content: center;
}

.timetable-container h1{
      font-family: 'RobotBold';
    color: #cb0600;
    text-align: center;
    margin-bottom: 40px;
    
}

.timetable-container{
    font-family: 'RobotRegular';
    text-align: center;
    color: white;
}

.dataTables_scrollHeadInner{
    padding-right: unset !important;
}

.timetable-container{
    width: 90%;
    border: #1a1a1a 3px solid;
    background-color:#0c0c0c;
    border-radius: 20px;
    padding: 30px;
}

.timeTable img{
    width: 80%;
}

.usernameButtons{

}

.buttonText{
    color: white;
}

.row{
    color: white !important;
}

th {
    color: rgb(255, 255, 255);
}

.custom-select{
    padding-right: 20px !important;
}

.activate-membership-container{
    display: grid;
    align-items: center;
    justify-content: center;
    justify-items: center;
    width: 100%;
}

.activate-membership-text-holder{
    width: 80%;
    display: flex;
    /* justify-content: center; */
    justify-items: center;
    margin-bottom: 60px;
}

.activate-membership-text-holder h1{
      font-family: 'RobotBold';
    color: white;
    font-style: italic;
    margin-right: 40px;
    white-space: nowrap;
    
}

.activate-membership-text-holder h2{
    color: #cb0600;
    font-size: 40px;
    margin-bottom: 20px;
    font-family: 'RobotBold';    
}

.activate-membership-text-holder p{
    font-family: 'RobotRegular';
    color: white;
    font-size: 20px;
    font-weight: 700;
    
}

.who-are-we-section-img-mobile{
    display: none;
}


@media screen and (max-width: 1050px) {
    .assign-memebrship-text p{
    font-family: 'RobotRegular';
        /* padding-left: 0 !important; */
    }

    .usernameInput{
        width: 70%;
    }

    .uploadMembership{
        display: block;
    }
}

@media screen and (max-width: 650px) {
    .assign-memebrship-text p{
    font-family: 'RobotRegular';
        /* padding-left: 0 !important; */
    }

    .usernameInput{
        width: 100%;
    }

    .uploadMembership{
        display: block;
    }
}
  
@media screen and (max-width: 655px) {
    .table-container{
        margin-bottom: 0.5em !important;
        margin-right: 0.5em !important;
        margin-left: 0.5em !important;
        padding: 0.1em;
    }

    .assign-memebrship-text h1{
      font-family: 'RobotBold';
        font-size: 20px;
        margin-bottom: 20px;
    }
    
    .assign-memebrship-text p{
    font-family: 'RobotRegular';
        font-size: 15px;
    }

    .table-container h1{
      font-family: 'RobotBold';
        font-size: 20px;
    }

    .assign-memebrship-text em{
        font-size: 18px;
    }
}

@media screen and (max-width: 1350px) and (min-width: 900px){
    .activate-membership-text-holder h2{
        font-size: 30px;
    }

    .activate-membership-text-holder h1{
      font-family: 'RobotBold';
        font-size: 30px;
    }
}

@media screen and (max-width: 900px){
    .activate-membership-text-holder{
        flex-direction: column;
    }

    .activate-membership-text-holder h2{
        font-size: 30px;
    }

    .advantage-section h1{
      font-family: 'RobotBold';
        /* text-align: left; */
        margin-bottom: 30px;
    }

    .timeTable{
        padding-left: 0;
        padding-right: 0;
    }

    .timeTable img{
        width: 100%;
    }

    .timetable-container {
        width: 100%;
        border: none;
        background-color: rgba(0, 0, 0, 0);
        border-radius: 0;
        padding: 0;
    }
    .membershipNumber{
        height: auto;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 10px;
        margin-bottom: 5px;
    }

    .step{
        text-align: left !important;
    }

    .who-are-we-section-img{
        display: none;
    }

    .who-are-we-section-img-mobile{
        display: block;
    }

    
}