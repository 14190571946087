.solution-section{
    padding-top: 150px;
    padding-bottom: 150px;
    width: 100%;
}

.solution-container h1{
      font-family: 'RobotBold';
    text-align: center;
    color: #cb0600;
    font-size: 35px;
    margin-bottom: 50px;
    font-weight: 700;
    
}

.solution-container p{
    font-family: 'RobotRegular';
    
}

.solution-table{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.solution-table img{
    width: 60%;
    background-color: rgba(19, 19, 19, 0.9);
    border-radius: 20px;
    padding: 30px;
}

.team-section{
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
}

.team-container h1{
      font-family: 'RobotBold';
    text-align: center;
    color: #cb0600;
    font-size: 35px;
    margin-bottom: 50px;
    font-weight: 700;
}

.team-table{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.team-table img{
    width: 90%;
}

.team-container-mobile h1{
      font-family: 'RobotBold';
    text-align: center;
    color: #cb0600;
    font-size: 35px;
    margin-bottom: 50px;
    font-weight: 700;
}

.team-container-mobile{
    display: none;
}

@media screen and (max-width:900px) {
    .solution-table img {
        width: 100%;
        padding: 0px;
    }
    .team-container{
        display: none;
    }
    .team-container-mobile{
        display: flex;
        flex-direction: column;
    }
    .team-table{
        margin-bottom: 30px;
    }
}