@media screen and (max-width:655px) {
    .background video{
        height: 100% !important;
        width: 100% !important;
    }

    .logo-container{
        left: 0% !important;
        width: 100% !important;
    }

    .logo-container2{
        left: 0% !important;
        width: 100% !important;
    }

    .logo-container p{
    font-family: 'RobotRegular';
        color: rgb(233, 233, 233);
        text-align: center;
        font-size: 15px !important;
        width:100% !important;
    }

    .background-pc{
        display: none !important;
    }

    .verifyCheckbox{
        cursor: pointer;
        width: 40px !important;
        height: 40px;
        margin-right: 30px;
        margin-top: 5px;
    }

    .verifiyContainer{
        justify-content: unset !important;
        margin-left: 5px;
    }


    .verifiyContainer a{
        color: white;
    }

    .verifyCheckbox2{
        cursor: pointer;
        width: 40px !important;
        height: 40px;
        margin-right: 30px;
        margin-top: 5px;
    }

    .verifiyContainer2{
        justify-content: unset !important;
    }


    .verifiyContainer2 a{
        color: white;
    }
}

@media screen and (max-width: 850px)  and (min-width: 655px){
    .logo-containerp {
    font-family: 'RobotRegular';
        font-size: 20px !important;
    }

    .logo-container{
        width: 70vw !important;
        left: 15% !important;
    }

    .background-pc{
        display: none !important;
    }
}

@media screen and (max-width: 1300px) and (min-width: 850px){
    .logo-containerp {
    font-family: 'RobotRegular';
        font-size: 2vh !important;
    }

    .logo-container{
        /* width: 0vw !important;
        left: 20% !important; */
        padding-left: 10px;
    }

    .background-pc{
        display: none !important;
    }

}

@media screen and (min-width: 850px){

    .logo-container2{
        display: none !important;
    }

    .promo-container{
        display: none !important;
    }

    .background-mobile{
        display: none;
    }
}

.down-arrow {
    background: rgba(0, 0, 0, 0);
    position: relative;
    width: 100%;
}
  
.down-arrow::before {
    animation: bounce 3s ease infinite;
    bottom: 2rem;
    color: #fff;
    content: '╲╱';
    font-size: 2rem;
    height: 4rem;
    left: 50%;
    letter-spacing: -1px;
    line-height: 4rem;
    margin-left: -3rem;
    opacity: 0.8;
    position: absolute;
    text-align: center;
    width: 6rem;
}

.down-arrow2 {
    background: rgba(0, 0, 0, 0);
    position: relative;
    width: 100%;
}
  
.down-arrow2::before {
    animation: bounce 3s ease infinite;
    bottom: 2rem;
    color: #fff;
    content: '╲╱';
    font-size: 2rem;
    height: 4rem;
    left: 50%;
    letter-spacing: -1px;
    line-height: 4rem;
    margin-left: -3rem;
    opacity: 0.8;
    position: absolute;
    text-align: center;
    width: 6rem;
}


@keyframes bounce {
    50% {
        transform: translateY(-50%);
    }
}

.page-title h5{
    color: white;
    font-size: 50px;
    font-weight: 700;
    font-family: 'RobotBold';
}

.verifyCheckbox{
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-right: 30px;
    margin-top: 5px;
}

.verifiyContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    /* align-items: center;
    margin-left: 20%;*/
    margin-bottom: 10px;
    color: white;
    font-style: italic;
    font-size: 25px;
}


.verifiyContainer a{
    color: white;
}

.verifyCheckbox2{
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-right: 30px;
    margin-top: 5px;
}

.verifiyContainer2{
    width: 100%;
    display: flex;
    justify-content: center;
    /* align-items: center;
    margin-left: 20%;*/
    margin-bottom: 10px;
    color: white;
    font-style: italic;
    font-size: 25px;
}


.verifiyContainer2 a{
    color: white;
}

.background{
    background: url("");
    width:100%;
    height: 100%;
}

.background-home{
    width:100%;
    min-height: 100vh;
    background-image: url("./../../imagesNew/Background.png");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}

.background-home-section{
    width:100%;
    height: fit-content;
    /* background-image: url("./../../imagesNew/Background.png");
    background-size: cover; */
}

.section-titles{
    width: 100%;
    height: fit-content;
    padding-top: 200px;
    padding-bottom: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    background-image: url("./../../imagesNew/Background.png");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}

.background-home-section-banner{
    background-image: url("./../../imagesNew/Background.png");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    height: fit-content;
}

.background-home2{
    color: rgba(0,0,0,0.5);
    width:100%;
    height: 100vh;
}

.background video{
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
}

.background-home video{
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
}

/* .logo-container{
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(0, -50%);
    width: 60vw;
    height: auto;
} */

.landing-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.landing-container-center{
    display: flex;
    width: 100%;
    /* height: 100%; */
    height: 80vh;
    justify-content: center;
    align-items: center;
}

.landing-container-center-mobile{
    display: none;
    width: 100%;
    /* height: 100%; */
    height: 80vh;
    justify-content: center;
    align-items: center;
}

.landing-container-center-container{
    display: flex;
    width: 60%;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.landing-container-center-container-mobile{
    display: grid;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    justify-items: center;
}

.landing-container-center-left{
    flex: 30%;
    width: 30%;
}

.landing-container-center-left img{
    width: 100%;
}

.landing-container-center-right{
    flex: 70%;
    width: 70%;
    display: grid;
    align-items: center;
    justify-content: center;
    justify-items: center;
}

.landing-container-center-right-signup-btn{
    background-color: #cb0600;
    color: rgb(255, 231, 231);
    margin-bottom: 20px;
    margin-top: 20px;
    border-radius: 20px;
    width: 100%;
    font-weight: 700;
    /* outline: solid 1px black; */
    font-size: 20px;
    width: fit-content;
    padding: 0.2em;
    padding-right: 20px;
    padding-left: 20px;
    cursor: pointer;
    /* box-shadow: 4px -4px 4px 2px #7a0000 inset; */
    box-shadow: 4px -4px 4px 2px #500000 inset;
    font-family: 'RobotBold';
    border: solid black 2px;
}

.landing-container-center-right-signup-btn:hover{
    /* box-shadow: 4px -4px 4px 2px #a50000 inset; */
    box-shadow: 4px -4px 4px 2px #8a0000 inset;
    transition: ease-in-out 0.3s;
}

.landing-container-center-right-signup-btn:active{
    /* box-shadow: 4px -4px 4px 2px #500000 inset; */
    box-shadow: 4px -4px 4px 2px #7a0000 inset;
}

.landing-container-center-right-discord-btn{
    background-color: white;
    border-radius: 20px;
    font-weight: 700;
    /* outline: solid 1px black; */
    font-size: 20px;
    width: fit-content;
    padding: 0.2em;
    padding-right: 20px;
    padding-left: 20px;
    cursor: pointer;
    /* box-shadow: 4px -4px 4px 2px #8a8a8a inset; */
    box-shadow: 4px -4px 4px 2px rgb(95, 95, 95) inset;
    font-family: 'RobotBold';
    border: solid black 2px;
}

.discord-button{
    color: black !important;
    font-family: 'RobotBold';
}

.landing-container-center-right-discord-btn:hover{
    box-shadow: 4px -4px 4px 2px #bbbbbb inset;
    transition: ease-in-out 0.3s;
}

.landing-container-center-right-discord-btn:active{
    /* box-shadow: 4px -4px 4px 2px rgb(95, 95, 95) inset; */
    box-shadow: 4px -4px 4px 2px #8a8a8a inset;
}

.landing-container-center-right h1{
      font-family: 'RobotBold';
    color: white;
    word-wrap: normal;
    font-family: 'RobotBold';
}

.grey-bk-section{
    background-color: #141414;
    height: fit-content;
    padding-top: 200px;
    padding-bottom: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
}

.who-are-we-section{
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.who-are-we-section-img{
    width: 30%;
}

.who-are-we-section-img img{
    width: 100%;
    height: auto;
}

.who-are-we-section-text{
    width: 70%;
}

.who-are-we-section-text h1{
      font-family: 'RobotBold';
    color: #cb0600;
    font-size: 35px;
    margin-bottom: 50px;
    font-weight: 700;
    
}

.who-are-we-section-text h2{
    color: white;
    font-size: 30px;
    margin-bottom: 20px;
    font-weight: 700;
    
}

.who-are-we-section-text p{
    font-family: 'RobotRegular';
    color: white;
    font-size: 20px;
    font-weight: 500;
    
}

.who-are-we-img{
    padding-right: 50px;
}

.who-are-we-section-img-mobile{
    display: none;
    width: 40%;
    margin-bottom: 50px;
}

.who-are-we-section-img-mobile img{
    width: 100%;
}

.S1Icon{
    padding-left: 50px;
}


.how-it-works-section{
    width: 100%;
    height: fit-content;
    padding-top: 250px;
    padding-bottom: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
}

/* .how-it-works-section-text{
    width: 70%;
    display: grid;
    align-items: center;
    justify-content: center;
    justify-items: center;
}

.how-it-works-section-text h1{
      font-family: 'RobotBold';
    color: #cb0600;
    text-align: center;
    font-size: 40px;
    margin-bottom: 50px;
    font-weight: 700;
}

.how-it-works-section-text p{
    font-family: 'RobotRegular';
    color: white;
    font-size: 30px;
    font-weight: 500;
}

.how-it-works-section-text em{
    font-style: italic;
    color: #cb0600;
    margin-right: 50px;
    font-size: 30px;
    font-weight: 700;
} */

.how-it-works-section2{
    display: grid;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    width: 100%;
}

.background-home-how-it-works{
    width:100%;
    min-height: 100vh;
    background-image: url("./../../imagesNew/Background.png");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}

.how-it-works-section-text-holder{
    width: 100%;
    display: flex;
    /* justify-content: center; */
    justify-items: center;
    margin-bottom: 60px;
}

.how-it-works-section-text-holder h1{
      font-family: 'RobotBold';
    color: rgb(255, 0, 0);
    font-style: italic;
    margin-right: 40px;
    white-space: nowrap;
    font-size: 32px;
    
}

.how-it-works-section-text-holder p{
    font-family: 'RobotRegular';
    color: white;
    margin-right: 40px;
    font-size: 29px;
    font-weight: 500;
    
}

.how-it-works-section2 h1{
      font-family: 'RobotBold';
    color: #cb0600;
    font-size: 40px;
    font-weight: 700;
    
}

.title-how-it-works{
    margin-bottom: 80px;
}


.advantage-section{
    padding-top: 200px;
    padding-bottom: 200px;
}

.advantage-section h1{
      font-family: 'RobotBold';
    color: #cb0600;
    text-align: center;
    font-size: 40px;
    margin-bottom: 50px;
    font-weight: 700;
    
}

.advantage-section-container{
    display: grid;
    justify-items: center;
    justify-content:center;
    align-items: center;  
}

.advantage-card-text{
    font-family: 'RobotRegular';
}

.advantage-top-row{
    width: 80%;
    display: flex;
    justify-content:space-between;
    align-items: baseline;  
    margin-bottom: 50px; 
}

.advantage-bottom-row{
    width: 80%;
    display: flex;
    justify-content:space-between;
    align-items: baseline;   
}

.advantage-card{
    width: 30%;
    display: grid;
    justify-items: center;
    justify-content: center;
    align-items: center;
}

.advantage-card h2{
    color: white;
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    min-height: 70px;
}

.advantage-card-img{
    width: 25%;
    margin-bottom: 20px;
}

.advantage-card-img img{
    width: 100%;
    height: auto;
}

.advantage-card-text{
    width: 80%;
    color: white;
    font-size: 20px;
    text-align: center;
    font-weight: 700;
}

.join-club-section{
    width: 100%;
    height: fit-content;
}

.join-club-section h4{
    color: #cb0600;
    text-align: center;
    margin-bottom: 100px;
    font-size: 40px;
    font-family: 'RobotBold';
}

.join-club-section-container{
    width: 100%;
    display: grid;
    justify-items: center;
    align-items: center;  
}

.club-packages-containers{
    width: 80%;
    display: flex;
    justify-items: center;
    justify-content:space-between;
    align-items: center;  
    margin-bottom: 100px;
}

.package-card{
    text-align: center;
    min-width: 25%;
    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
    border: solid 1px white;
    /* background: #0a0a0a; */
    box-shadow:
    0px 0px 2px rgb(230, 230, 230),
    0px 0px 3px rgb(230, 230, 230),
    0px 0px 5px rgb(230, 230, 230),
    0px 0px 5px rgb(230, 230, 230),
    0px 0px 6px rgb(230, 230, 230),
    0px 0px 7px rgb(230, 230, 230),
    0px 0px 13px rgb(230, 230, 230),
    0px 0px 15px rgb(230, 230, 230),
    0px 0px 18px rgb(230, 230, 230),
    0px 0px 20px rgb(230, 230, 230);
    border-radius: 5px;
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='500' height='800' preserveAspectRatio='none' viewBox='0 0 500 800'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1780%26quot%3b)' fill='none'%3e%3crect width='500' height='800' x='0' y='0' fill='url(%23SvgjsLinearGradient1781)'%3e%3c/rect%3e%3cpath d='M500 0L293.95 0L500 271.02z' fill='rgba(255%2c 255%2c 255%2c .1)'%3e%3c/path%3e%3cpath d='M293.95 0L500 271.02L500 345.04999999999995L163.42 0z' fill='rgba(255%2c 255%2c 255%2c .075)'%3e%3c/path%3e%3cpath d='M163.41999999999996 0L500 345.04999999999995L500 573.39L100.21999999999996 0z' fill='rgba(255%2c 255%2c 255%2c .05)'%3e%3c/path%3e%3cpath d='M100.21999999999997 0L500 573.39L500 670.4399999999999L40.59999999999997 0z' fill='rgba(255%2c 255%2c 255%2c .025)'%3e%3c/path%3e%3cpath d='M0 800L106.83 800L0 571.83z' fill='rgba(0%2c 0%2c 0%2c .1)'%3e%3c/path%3e%3cpath d='M0 571.83L106.83 800L142.3 800L0 544.98z' fill='rgba(0%2c 0%2c 0%2c .075)'%3e%3c/path%3e%3cpath d='M0 544.98L142.3 800L223.03000000000003 800L0 539.23z' fill='rgba(0%2c 0%2c 0%2c .05)'%3e%3c/path%3e%3cpath d='M0 539.23L223.03000000000003 800L232.63000000000002 800L0 166.54000000000002z' fill='rgba(0%2c 0%2c 0%2c .025)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1780'%3e%3crect width='500' height='800' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='115%25' y1='90.62%25' x2='-15%25' y2='9.38%25' gradientUnits='userSpaceOnUse' id='SvgjsLinearGradient1781'%3e%3cstop stop-color='rgba(41%2c 41%2c 41%2c 1)' offset='0.54'%3e%3c/stop%3e%3cstop stop-color='rgba(0%2c 0%2c 0%2c 1)' offset='0.98'%3e%3c/stop%3e%3cstop stop-color='rgba(0%2c 0%2c 0%2c 1)' offset='0.99'%3e%3c/stop%3e%3cstop stop-color='rgba(34%2c 0%2c 0%2c 1)' offset='1'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e"); */
    background-image: url("./../../imagesNew/priceBK.png");
    transition: 0.4s ease-in-out;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.package-card:hover{
    box-shadow:
    0px 0px 20px rgb(230, 230, 230),
    0px 0px 22px rgb(230, 230, 230),
    0px 0px 24px rgb(230, 230, 230),
    0px 0px 25px rgb(230, 230, 230),
    0px 0px 26px rgb(230, 230, 230),
    0px 0px 28px rgb(230, 230, 230),
    0px 0px 30px rgb(230, 230, 230);
    transform: scale(1.02); 
}

.package-card-middle{
    text-align: center;
    min-width: 25%;
    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
    border: solid 1px white;
    background: #0a0a0a;
    box-shadow:
    0px 0px 2px rgb(230, 230, 230),
    0px 0px 3px rgb(230, 230, 230),
    0px 0px 5px rgb(230, 230, 230),
    0px 0px 5px rgb(230, 230, 230),
    0px 0px 6px rgb(230, 230, 230),
    0px 0px 7px rgb(230, 230, 230),
    0px 0px 13px rgb(230, 230, 230),
    0px 0px 15px rgb(230, 230, 230),
    0px 0px 18px rgb(230, 230, 230),
    0px 0px 20px rgb(230, 230, 230); 
    border-radius: 5px;
    margin-bottom: 70px;
    transition: 0.4s ease-in-out;
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='500' height='800' preserveAspectRatio='none' viewBox='0 0 500 800'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1780%26quot%3b)' fill='none'%3e%3crect width='500' height='800' x='0' y='0' fill='url(%23SvgjsLinearGradient1781)'%3e%3c/rect%3e%3cpath d='M500 0L293.95 0L500 271.02z' fill='rgba(255%2c 255%2c 255%2c .1)'%3e%3c/path%3e%3cpath d='M293.95 0L500 271.02L500 345.04999999999995L163.42 0z' fill='rgba(255%2c 255%2c 255%2c .075)'%3e%3c/path%3e%3cpath d='M163.41999999999996 0L500 345.04999999999995L500 573.39L100.21999999999996 0z' fill='rgba(255%2c 255%2c 255%2c .05)'%3e%3c/path%3e%3cpath d='M100.21999999999997 0L500 573.39L500 670.4399999999999L40.59999999999997 0z' fill='rgba(255%2c 255%2c 255%2c .025)'%3e%3c/path%3e%3cpath d='M0 800L106.83 800L0 571.83z' fill='rgba(0%2c 0%2c 0%2c .1)'%3e%3c/path%3e%3cpath d='M0 571.83L106.83 800L142.3 800L0 544.98z' fill='rgba(0%2c 0%2c 0%2c .075)'%3e%3c/path%3e%3cpath d='M0 544.98L142.3 800L223.03000000000003 800L0 539.23z' fill='rgba(0%2c 0%2c 0%2c .05)'%3e%3c/path%3e%3cpath d='M0 539.23L223.03000000000003 800L232.63000000000002 800L0 166.54000000000002z' fill='rgba(0%2c 0%2c 0%2c .025)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1780'%3e%3crect width='500' height='800' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='115%25' y1='90.62%25' x2='-15%25' y2='9.38%25' gradientUnits='userSpaceOnUse' id='SvgjsLinearGradient1781'%3e%3cstop stop-color='rgba(41%2c 41%2c 41%2c 1)' offset='0.54'%3e%3c/stop%3e%3cstop stop-color='rgba(0%2c 0%2c 0%2c 1)' offset='0.98'%3e%3c/stop%3e%3cstop stop-color='rgba(0%2c 0%2c 0%2c 1)' offset='0.99'%3e%3c/stop%3e%3cstop stop-color='rgba(34%2c 0%2c 0%2c 1)' offset='1'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e"); */
    background-image: url("./../../imagesNew/priceBK.png");
    transition: 0.4s ease-in-out;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.package-card-middle:hover{
    box-shadow:
    0px 0px 20px rgb(230, 230, 230),
    0px 0px 22px rgb(230, 230, 230),
    0px 0px 24px rgb(230, 230, 230),
    0px 0px 25px rgb(230, 230, 230),
    0px 0px 26px rgb(230, 230, 230),
    0px 0px 28px rgb(230, 230, 230),
    0px 0px 30px rgb(230, 230, 230);
    transform: scale(1.02); 
}


.price-section{
    color: white;
    border-bottom: #500000 solid 2px;
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.price-section h1{
    color:  #feffff;
    font-family: 'Avenir Next LT Pro Bold';
}

.price-section h3{
    color:  #959595;
    font-family: 'Avenir Next LT Pro Regular';
}

.length-section{
    color:  #feffff;
    font-family: 'Avenir Next LT Pro Bold';
}

.bottom-section h1{
    font-family: 'Avenir Next LT Pro Ultra Light';
}

.bottom-section p{
    font-family: 'Avenir Next LT Pro Regular';
    color:  #959595;
}

.length-section{
    color: white;
    border-bottom: #500000 solid 2px;
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.bottom-section{
    color: white;
}

.join-button{
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    box-shadow: 4px -4px 4px 2px #7a0000 inset;
    border-radius: 30px;
    background-color: #cb0600;
    color: white;
    font-weight: 700;
    font-size: 20px;
    padding: 0.25em;
    padding-left: 20px;
    padding-right: 20px;
}

.join-button:hover{
    box-shadow: 4px -4px 4px 2px #a50000 inset;
}

.join-button:active{
    box-shadow: 4px -4px 4px 2px #500000 inset;
}


.logo-container{
    position: absolute;
    top: 45%;
    left: 35%;
    transform: translate(0, -50%);
    width: 30vw;
    height: auto;
}

/* .logo-container{
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(0, -50%);
    width: 100%;
    height: auto;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgb(0, 0, 0, 0.1) 90%);
    padding: 50vh;

    padding-right: 50em;
    padding-left: 50em;
    padding-top: 50em;
    padding-bottom: 50em;

} */

.logo-container2{
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    height: auto;
    box-shadow: 50px 10px 60px 350px rgb(0, 0, 0, 0.5);
    background-color: black;

}

.logo-container2 img{
    width: 100%;
    height: auto;
    /* padding-left: 50px;
    padding-right: 50px; */
}

.logo-container p{
    font-family: 'RobotRegular';
    color: rgb(233, 233, 233);
    text-align: center;
    font-size: 2.3vh;
    /* width: 30vw; */
    
    font-weight: 500;
}

.logo-container img{
    width: 95%;
    height: auto;
    padding-left: 50px;
    padding-right: 50px;
}

.main-container{
    width:100%;
    height:600vh;
}

.promo-container{
    cursor: pointer;
    position: relative;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
}

.promo-container video{
    border: solid #505050;
    border-radius: 20px;
    width: 100%;
}

@media screen and (max-width: 1350px) and (min-width: 900px) {
    .landing-container-center-right h1{
      font-family: 'RobotBold';
        font-size: 30px;
    }

    .who-are-we-section-text h1{
      font-family: 'RobotBold';
        font-size: 30px;
    }

    .who-are-we-section-text p{
    font-family: 'RobotRegular';
        font-size: 17px;
    }

    .how-it-works-section-textp {
    font-family: 'RobotRegular';
        font-size: 25px;
    }

    .advantage-card-text{
        font-size: 15px;
    }

    .advantage-card h2{
        font-size: 25px;
    }

    .club-packages-containers{
        width: 90%;
    }
    
    .package-card{
        min-width: 30%;
    }
    
    .package-card-middle{
        min-width: 30%;
    }

    .who-are-we-section-text h2{
        font-size: 25px;
    }

    .how-it-works-section-text-holder h1{
      font-family: 'RobotBold';
        font-size: 25px;
    }
    
    .how-it-works-section-text-holder p{
    font-family: 'RobotRegular';
        font-size: 20px;
    }

    .how-it-works-section2 h1{
      font-family: 'RobotBold';
        color: #cb0600;
        font-size: 30px;
    }
}


@media screen and (max-width: 900px){
    .grey-bk-section{
        padding-top: 120px;
        padding-bottom: 120px;
    }

    .how-it-works-section2{
        padding-top: 120px;
        padding-bottom: 120px;
    }

    .landing-container-center{
        display: none;
    }

    .landing-container-center-mobile{
        display: flex;
    }

    .landing-container-center-right{
        width: 100%;
    }

    .landing-container-center-right h1{
      font-family: 'RobotBold';
        font-size: 20px;
    }

    .landing-container-center-left{
        width: 60%;
    }

    .landing-container-center-right-signup-btn{
        font-size: 16px;
        padding-bottom: 5px;
    }

    .landing-container-center-right-discord-btn{
        font-size: 16px;
    }

    .who-are-we-section-text{
        width: 90%;
    }

    .who-are-we-section{
        display: grid;
        justify-items: center;
        width: 100%;
    }

    .who-are-we-section-img{
        width: 50%;
    }

    .who-are-we-img{
        padding-right: 0;
        margin-bottom: 50px;
    }

    .who-are-we-section-text h1{
      font-family: 'RobotBold';
        text-align: center;
        font-size: 30px;
    }

    .who-are-we-section-text p{
    font-family: 'RobotRegular';
        font-size: 18px;
    }

    .title-how-it-works h1{
      font-family: 'RobotBold';
        font-size: 30px;
    }

    .how-it-works-section-text-holder{
        display: grid;
        justify-content: center;
        justify-items: center;
        align-items: center;
    }

    .how-it-works-section-text-holder p{
    font-family: 'RobotRegular';
        font-size: 18px;
    }

    .how-it-works-section2 p{
    font-family: 'RobotRegular';
        text-align: center;
        margin-right: 0;
        padding-left: 5%;
        padding-right: 5%;
    }

    .how-it-works-section2 h1{
      font-family: 'RobotBold';
        font-size: 30px;
        margin-right: 0;
    }

    .who-are-we-section-text h2{
        font-size: 22px;
    }

    .who-are-we-section-text p{
    font-family: 'RobotRegular';

    }

    .S1Icon{
        display: none;
    }

    .who-are-we-section-img-mobile{
        display: flex;
        justify-items: center;
    }

    .S1Icon {
        padding-left: 0;
    }

    .advantage-section {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    .advantage-section h1{
      font-family: 'RobotBold';
        font-size: 30px;
    }

    .advantage-top-row{
        display: grid;
    }

    .advantage-bottom-row{
        display: grid;
    }

    .advantage-card {
        width: 100%;
        margin-bottom: 50px;
    }

    .advantage-card-text{
        font-size: 17px;
    }

    .advantage-card h2{
        font-size: 26px;
    }

    .join-club-section h4{
        font-size: 30px;
        padding-left: 20px;
        padding-right: 20px;
        font-family: 'RobotBold';
    }

    .club-packages-containers{
        flex-direction: column;
    }

    .package-card-middle{
        margin-top: 70px;
    }

    .legal-section{
        padding-left: 20px;
        padding-right: 20px;
    }

    .verifiyContainer p{
    font-family: 'RobotRegular';
        font-size: 15px;
    }

    .verifiyContainer2 p{
    font-family: 'RobotRegular';
        font-size: 15px;
    }

    .package-card {
        min-width: 75%;
    }

    .package-card-middle{
        min-width: 75%;
    }

    .club-packages-containers{
        width: 100%;
    }

    .down-arrow2 {
        background: rgba(0, 0, 0, 0);
        position: relative;
        width: 100%;
        padding-top: 50px;
    }

    .down-arrow {
        background: rgba(0, 0, 0, 0);
        position: relative;
        width: 100%;
    }
      
    .down-arrow {
       /* margin-top: 60px; */
    }

    .background-home{
        background-attachment: unset;
        background-image: url("./../../imagesNew/mobileBK2.png");
        background-image: url("https://venftian.s3.eu-west-2.amazonaws.com/mobileBK.png");
    }

    .background-home-how-it-works{
        background-attachment: unset;
        background-image: url("https://venftian.s3.eu-west-2.amazonaws.com/mobileBK.png");
    }

    .section-titles{
        background-attachment: unset;
        background-image: url("./../../imagesNew/mobilehbk.png") !important;
    }

    .background-home-section-banner{
        /* background-image: url("./../../imagesNew/mobilehbk.png"); */
    }
    

}
