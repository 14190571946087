.FAQ-Text{
    /* padding:3em; */
    border: #505050 solid;
    border-radius: 15px;
    margin-bottom: 5em;
    margin-right: 3em;
    margin-left: 3em;
}

.FAQ-Text em{
    
    color: rgb(201, 3, 3);
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
}

.FAQ-Text font{
    
    color: rgb(255, 0, 0);
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
}

.FAQ-Text h1{
      font-family: 'RobotBold';
    /* font-family: 'poker'; */
    
    color: white; 
    text-align: center;
    margin-bottom: 30px;
    background-color: #1a1a1a;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    font-size: 30px;
    padding: 10px;
}

.FAQ-Text p{
    font-family: 'RobotRegular';
    text-align: left;
    /* font-family: 'poker'; */
    
    font-size: 20px;
    font-weight: 400;
    /* padding-left: 10vh; */
    color: white;
    padding-left: 3em;
    padding-right: 2em;
    padding-bottom: 1em;
}

.title-faq-mobile{
    font-size: 40px;
    margin: 0 auto;
    font-weight: 400;
    color: #f1f1f1;
    /* font-family: 'poker'; */
    /* font-family:  Verdana, sans-serif; */
    
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 5vh; */
    background-color: #1a1a1a;
    display: none;
    text-align: center;
}

.title-faq{
    font-size: 50px;
    margin: 0 auto;
    font-weight: 400;
    color: #f1f1f1;
    /* font-family: 'poker'; */
    /* font-family:  Verdana, sans-serif; */
    
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 5vh; */
    background-color: #1a1a1a;
}

.faq-container{
    display: grid;
    width: 100%;
    justify-content: center;
    justify-items: center;
}

.faq-container-text h1{
      font-family: 'RobotBold';
    color: #cb0600;
    
}

.footer-background{
    width: 100%;
    height: fit-content;
    padding-top: 200px;
    padding-bottom: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    background-image: url("./../../imagesNew/Background.png");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;    
}


@media screen and (max-width:655px) {
    .FAQ-Text h1{
      font-family: 'RobotBold';
        font-size: 20px !important;
        margin-bottom: 20px;
    }
    
    .FAQ-Text p{
    font-family: 'RobotRegular';
        font-size: 15px !important;
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    .FAQ-Text em{
        font-size: 18px !important;
    }

    .FAQ-Text font{
        font-size: 20px !important;
    }
}

@media screen and (max-width: 850px) {
    .FAQ-Text{
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .title-faq-mobile{
        display: block;
    }

    .title-faq{
        display: none;
    }

    .AccordionHolder{
        margin-bottom: 5em;
        margin-right: 0.8rem !important;
        margin-left: 0.8rem !important;
    }

    .faq__question-button {
        padding-left: 1.3rem !important;
        padding-right: 0.1rem;
        text-align: left !important;
        display: flex !important;
    }
    
}

.AccordionHolder{
    /* padding:3em; */
    margin-bottom: 5em;
    /* margin-right: 3em;
    margin-left: 3em; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.faq {
    width: 100%;
    padding-top: 65px;
    /* padding-bottom: 80px; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.faq__title {
    margin: 0;
    padding-bottom: 25px;
    color: white;
    display: none;
}
  
.faq__question:nth-child(1) {
    max-width: 1000px;
    width: 100%;
    font-size: 25px;
    border-top: #505050 solid;
    border-right: #505050 solid;
    border-left: #505050 solid;
    border-bottom: #505050 solid;
    color: white;
    border-radius: 15px;
}

.faq__question {
    max-width: 1000px;
    width: 100%;
    font-size: 25px;
    border-right: #505050 solid;
    border-left: #505050 solid;
    border-bottom: #505050 solid;
    color: white;
    border-radius: 15px;
}

.faq__question-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    background-color: #1a1a1a;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 0px;
    cursor: pointer;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 1rem;
    padding-right: 1rem;
    font-family: 'RobotBold';
    font-size: 20px;

}

.faq__question-button::after {
/* content: url(../images/icon-arrow-down.svg); */
    margin-right: 17px;
    transform: rotate(90deg);
}

.faq__question-button:hover {
    color: #cb0600;
}


.faq__desc {
    margin-left: -40px;
    /* padding-right: 17px; */
    padding-left: 60px;
    margin-top: 20px;
    margin-bottom: 0;
    padding-right: 1rem;
    font-size: 20px;
    height: 0;
    overflow: hidden;
    transition: all 200ms ease-out;
    color: white;
    font-size: 15px;
}

.faq__desc.show-description {
    height: fit-content;
    margin-bottom: 15px;
    font-family: 'RobotRegular';
  }
  
  /* Arrows */
  
  /* closed */
  .faq__question-button::after {
    transition: transform 300ms ease-out;
  }
  
  /* open */
  .font-weight-bold::after {
    transform: rotate(720deg);
  }

@media screen and (max-width: 900px) {
    .faq-container-text h1{
      font-family: 'RobotBold';
        text-align: center;
    }
    .faq__question-button {
        font-size: 20px;
    }
    .svg-inline--fa{
        padding-left: 5px;
    }
    .page-title h5{
        font-size: 40px;
    }
    .faq__desc {
        font-size: 16px !important;
    }
}

