@font-face {
  font-family: 'RobotBold';
  src: local("RobotBold"),
  url("../../fonts/RobotoCondensed-Bold.ttf") format("truetype");
}

@font-face {
  font-family: 'RobotBoldItalic';
  src: local("RobotBoldItalic"),
  url("../../fonts/RobotoCondensed-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: 'RobotItalic';
  src: local("RobotItalic"),
  url("../../fonts/RobotoCondensed-Italic.ttf") format("truetype");
}

@font-face {
  font-family: 'RobotLight';
  src: local("RobotLight"),
  url("../../fonts/RobotoCondensed-Light.ttf") format("truetype");
}

@font-face {
  font-family: 'RobotRegular';
  src: local("RobotRegular"),
  url("../../fonts/RobotoCondensed-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Avenir Next LT Pro Bold';
  src: local("Avenir Next LT Pro Bold"),
  url("../../fonts/Avenir Next LT Pro Bold.ttf") format("truetype");
}

@font-face {
  font-family: 'Avenir Next LT Pro Regular';
  src: local("Avenir Next LT Pro Regular"),
  url("../../fonts/Avenir Next LT Pro Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Avenir Next LT Pro Ultra Light';
  src: local("Avenir Next LT Pro Ultra Light"),
  url("../../fonts/AvenirNextLTPro-UltLt.ttf") format("truetype");
}


/* .nav-container{
  width: 100%;
  position: absolute;
} */


nav {
  /* padding: 10px; */
  display: flex;
  justify-content: space-between;
  /* background-color: rgb(10, 10, 10); */
  color: #fff;
  position: relative;
  width: 100%;
  z-index: 4 !important;
  font-size: 1.2rem;
  flex-wrap: nowrap !important;
  padding-right: 1EM;
  /* border-bottom: solid #505050;  */
  background-image: linear-gradient(rgb(0,0,0),rgba(0,0,0,0))
}

/* .nav-overlay {
  color: #fff;
  position: relative;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.7);
} */

.menu-holder{
  position: relative;
  right: 0;
}

.fas {
  cursor: pointer;
}

.menu-icon {
  display: none;
  font-size: 20px;
}
.menu-icon .fa-times {
  transform: rotate(360deg);
  transition: all 0.2s ease-in-out;
}

.menu-icon .fa-bars {
  transform: rotate(180deg);
  transition: all 0.2s ease-in-out;
}

.logo {
  height: 60px;
  width: 100%;
  color: rgb(255, 255, 255);
  /* font-family: "poker"; */
  font-family: 'RobotBold';
  padding-left: 2em;
  margin-top: 5px;
  display: flex;
  align-items: center;
  font-size: 28px;
}

.logo img{
  height: 100%;
  width: auto;
  padding-bottom: 5px;
}

.menu-list {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  float: right;
  margin-top: 15px;
  text-shadow: none;
  z-index: 5;
  opacity: 0.9 !important;
}

.menu-list.close{
  color: #fff;
  text-shadow: none;
  opacity: 1;
}

/* .menu-list li:last-child a {
  font-weight: 300;
  padding: 5px 10px;
  background-color: #cd0101;
  transition: all 0.5s ease-in-out;
  margin-left: 20px;
  border-radius: 10px;
} */

.menu-list li a {
  text-decoration: none;
  color: #cb0600;
  font-weight: 1000;
  padding: 5px 10px;
  margin-left: 20px;
  /* font-family: "poker"; */
  font-family: 'RobotBold';
  font-size: 26px;
  border-radius: 10px;
}

.menu-list li a:hover,
.menu-list li a.active {
  text-decoration: underline #fff;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  opacity: 1 !important;
}

@media screen and (max-width: 900px) {
  .logo {
    font-size: 20px;
  }
  .menu-list li a {
    font-size: 20px;
    margin-left: 10px;
  }
  .menu-list{
    
  }
  .nav{
    z-index: 5 !important;
    background: rgba(0, 0, 0, 0.85);
  }
}

@media screen and (max-width: 600px) {
  .menu-icon {
    display: block;
    margin-top: 20px;
  }
  .menu-list {
    flex-direction: column;
    width: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.95);
    padding: 20px;
    right: 0;
    top: 49px;
    transition: all 0.5s ease-in-out;
    border-bottom: #505050 solid;
  }

  .menu-list li {
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }
  .menu-list li:last-child {
    margin-bottom: 0px;
  }

  .menu-list li a,
  .menu-list li:last-child a {
    width: 100px;
    display: block;
    margin: auto;
  }

  .menu-list.close {
    top: -550%;
    transition: all 0.5s ease-in-out;
  }
}
